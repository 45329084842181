import { createSelector } from 'reselect'

import { RootState } from '../../store/store'

export const getChannel = (state: RootState) => state.channel

export const getChannelReadyState = createSelector(
  getChannel,
  ({ ready }) => ready || false,
)

export const getChannelAlertMessage = createSelector(
  getChannel,
  ({ alertMessage, alertType }) => ({ alertMessage, alertType }),
)

export const getChannelSenderIds = createSelector(
  getChannel,
  ({ senderIds }) => senderIds.filter((a) => !(/[a-zA-Z]+/).test(a.number)) || [],
)

export const getSendingConfigState = createSelector(
  getChannel,
  ({ isSendingConfig }) => isSendingConfig,
)
