import { Dispatch } from 'redux'
import _get from 'lodash/get'

import * as types from './channel-action-types'

import ExtensionContactApi from '../../apis/extension-contact-api'

const MISSING_SCOPE_ERROR = 'This app hasn\'t been granted all required scopes to make this call. Read more about required scopes here: https://developers.hubspot.com/scopes.'
const NO_BETA_ACCESS = 'You are not enrolled in the Conversation Inbox Beta Access'

export type TChannelConfiguration = {
  senderId: string,
  portalId: string,
  channelId: string,
  accountToken: string,
  inboxId: string,
}

export const getSenderIds = (query: {portalId: string}) => async (dispatch: Dispatch) => {
  dispatch({
    type: types.GET_CHANNEL_SENDER_IDS.REQUEST,
  })

  try {
    const response = await ExtensionContactApi.fetchSenderIds(query)
    dispatch({
      type: types.GET_CHANNEL_SENDER_IDS.SUCCESS,
      payload: await response.json(),
    })
  } catch (error) {
    dispatch({
      type: types.GET_CHANNEL_SENDER_IDS.FAILURE,
    })
    throw error
  }
}

export const sendConfiguration = (body: TChannelConfiguration) => async (dispatch: Dispatch) => {
  dispatch({
    type: types.SEND_CHANNEL_CONFIG.REQUEST,
  })

  const response = await ExtensionContactApi.sendConfiguration(body)

  if (response.status === 200) {
    dispatch({
      type: types.SEND_CHANNEL_CONFIG.SUCCESS,
    })
    return
  }

  dispatch({
    type: types.SEND_CHANNEL_CONFIG.FAILURE,
  })
  const errorResponse = await response.json()

  const errorMessage = _get(errorResponse, 'error', 'error.channel.default')
  if (errorMessage === MISSING_SCOPE_ERROR) {
    throw new Error('error.channel.missing-scope')
  }
  if (errorMessage === NO_BETA_ACCESS) {
    throw new Error('error.channel.program.beta.not-joined')
  }
  throw new Error(errorMessage)
}
