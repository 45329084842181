import {
  useState, useRef,
} from 'react'
import { useFormState } from 'react-final-form'
import Row from 'antd/lib/grid/row'
import Col from 'antd/lib/grid/col'
import Button from 'antd/lib/button'
import classNames from 'classnames'
import { FormattedMessage } from 'react-intl'

import SenderAndRecipientsCard from '../sender-and-recipients-card'
import MessageContentCard from '../message-content-card'
import PhonePreview from '../phone-preview'

import {
  TExtensionContactFormFieldProps,
  TFormValues,
  TSenderIds,
} from '../extension-contact-types'

import './extension-contact-form-fields.css'
import { useAppSelector } from '../../../store/hooks'
import {
  getAccountsAndSenderIds, getIsTrialUser, getSenderIds, getSurveyDone,
} from '../../../redux/contact/extension-contact-selectors'
import isVerifiedTFN from '../../../helpers/tfn-verification/tfn-verification'
import TrialAccountBanner from '../../trial-account-banner'
import { getSelfServeOnboardingFlag } from '../../../redux/release-flags/release-flags-selector'
import TypeformWidget from '../../typeform-widget/typeform-widget'
import ExtensionContactApi from '../../../apis/extension-contact-api'

function ExtensionContactFormFields(props: TExtensionContactFormFieldProps) {
  const surveyDone = useAppSelector(getSurveyDone)
  const hasCompletedSurvey = useRef(false)
  const [showTypeform, setShowTypeform] = useState(!surveyDone)

  const isTrialUser = useAppSelector(getIsTrialUser)
  const primarySenderIds = useAppSelector(getSenderIds)
  const {
    accountsAndSenderIds,
  } = useAppSelector(getAccountsAndSenderIds)
  const isSelfServeEnabled = useAppSelector(getSelfServeOnboardingFlag)

  const { invalid, values } = useFormState<TFormValues>()
  const senderIds = values.accountId
    ? accountsAndSenderIds?.filter((a) => a.accountKey === values.accountId)[0].accountSenderIds
    : primarySenderIds
  const isSenderIdVerified = isVerifiedTFN(senderIds as TSenderIds, values.senderId)

  const invalidSenderId = isSelfServeEnabled && !isTrialUser && !isSenderIdVerified

  const {
    processing,
    objectType,
    uuid,
    otp,
  } = props

  const onClickCloseTypeform = async () => {
    setShowTypeform(false)
    await ExtensionContactApi.postSurveyResponse({
      objectType,
      uuid,
      otp,
    }, false)
  }

  const onSurveyComplete = async (formId?: string, responseId?: string) => {
    await ExtensionContactApi.postSurveyResponse({
      objectType, uuid, otp, formId, responseId,
    }, true)
    hasCompletedSurvey.current = true
  }

  const onSurveyClose = async () => {
    if (hasCompletedSurvey.current) {
      setShowTypeform(false)
    }
  }

  return (
    <Row gutter={16} className="extension-contact-form-fields">
      <Col
        xs={24}
        sm={12}
        md={14}
        className="sender-message-card-col"
      >
        <SenderAndRecipientsCard
          {...props}
          invalidSenderId={invalidSenderId}
        />

        <MessageContentCard
          {...props}
        />
      </Col>

      <Col
        xs={24}
        sm={12}
        md={10}
        className="phone-preview-col"
      >
        <PhonePreview
          phoneSource={values.phoneSource}
        />
      </Col>

      <div className="button-bar">
        {
          showTypeform
          && !isTrialUser
          && (
            <div className="typeformWrapper">
              <TypeformWidget
                typeformId="jeDv4E6C"
                onSurveyCompleteCallback={onSurveyComplete}
                onClickCloseTypeformButton={onClickCloseTypeform}
                onSurveyClose={onSurveyClose}
              />
            </div>
          )
        }
        {
          isSelfServeEnabled
          && isTrialUser
          && <TrialAccountBanner />
        }
        <div className={classNames('button-wrapper', { full: (!isSelfServeEnabled || !isTrialUser) && !showTypeform })}>
          <Button
            type="primary"
            htmlType="submit"
            loading={processing}
            disabled={invalid || processing || invalidSenderId}
          >
            <FormattedMessage id="form.extension-contact.button.submit" />
          </Button>
        </div>
      </div>
    </Row>
  )
}

export default ExtensionContactFormFields
